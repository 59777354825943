<template>
  <el-dialog
    :title="dialogType == 'add' ? '添加规则' : '编辑规则'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="800px"
  >
    <div style="color: #ff8080;margin-bottom: 25px;">说明：虚拟报价起始值=虚拟报价基数-虚拟报价基数×起始值下浮比例，下限限制；虚拟报价结束值=虚拟报价基数-虚拟报价基数×结束值下浮比例，下限限制</div>
    <el-form ref="form" :model="formData" :rules="rules" label-width="135px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="报价区间：" required>
            <div style="display:flex">
              <el-form-item label-width="0" prop="lower">
                <el-input v-model="formData.lower" placeholder="请输入起始值" @input="inputNumber($event, formData, 'lower', 0)" />
              </el-form-item>
              <div style="margin:0 10px">~</div>
              <el-form-item label-width="0" prop="upper">
                <el-input v-model="formData.upper" placeholder="请输入结束值" @input="inputNumber($event, formData, 'upper', 0)" />
              </el-form-item>
            </div>
          </el-form-item>
        </el-col>
        <div style="padding-left: 10px;font-weight: 800;">虚拟报价起始值</div>
        <el-col :span="12">
          <el-form-item label="允许下浮比例：" prop="lowerRate">
            <el-input v-model="formData.lowerRate" placeholder="请输入小于90的数字" @input="inputNumber($event, formData, 'lowerRate', 0, 90)">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最多允许下浮：" prop="lowerLimit">
            <el-input v-model="formData.lowerLimit" placeholder="请输入金额" @input="inputNumber($event, formData, 'lowerLimit', 0, 99999)">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <div style="padding-left: 10px;font-weight: 800;">虚拟报价结束值</div>
        <el-col :span="12">
          <el-form-item label="允许下浮比例：" prop="upperRate">
            <el-input v-model="formData.upperRate" placeholder="请输入小于90的数字" @input="inputNumber($event, formData, 'upperRate', 0, 90)">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最多允许下浮：" prop="upperLimit">
            <el-input v-model="formData.upperLimit" placeholder="请输入金额" @input="inputNumber($event, formData, 'upperLimit', 0, 99999)">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { inputNumber } from "@/utils/common"
export default {
  name: "VirtualQuoteRuleDia",
  data() {
    const validateRange = (rule, value, callback) => {
      const { field } = rule
      const { lower, upper } = this.formData
      if (!lower || !upper) callback()
      if (Number(lower) >= Number(upper)) {
        if (field == 'lower') callback(new Error('起始值需小于结束值'))
        callback(new Error('结束值需大于起始值'))
      }
      callback()
    }
    const rateVaild = (rule, value, callback) => {
      const { field } = rule
      const { lowerRate, upperRate } = this.formData
      if (!lowerRate || !upperRate) callback()
      if (Number(lowerRate) < Number(upperRate)) {
        if (field == 'lowerRate') callback(new Error('起始值大于等于结束值'))
        callback(new Error('结束值需小于等于起始值'))
      }
      callback()
    }
    // const limitLenthVaild = (rule, value, callback) => {
    //   const number = Number(value)
    //   if (number < 0 || number > 100) {
    //     callback(new Error('请输入不大于100的数字'))
    //   }
    //   callback()
    // }
    // const limitLenthVaild_200 = (rule, value, callback) => {
    //   const number = Number(value)
    //   if (number < 0 || number > 200) {
    //     callback(new Error('请输入不大于200的数字'))
    //   }
    //   callback()
    // }
    return {
      visible: false,
      loading: false,

      dialogType: '',
      formData: {
        lower: '',
        upper: '',
        lowerRate: '',
        lowerLimit: '',
        upperRate: '',
        upperLimit: ''
      },
      rules: {
        lower: [
          { required: true, message: '请输入起始值', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        upper: [
          { required: true, message: '请输入结束值', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        lowerRate: [
          { required: true, message: '请输入比例', trigger: 'blur' },
          { validator: rateVaild, trigger: 'blur' }
        ],
        lowerLimit: [
          { required: true, message: '请输入金额', trigger: 'blur' },
        ],
        upperRate: [
          { required: true, message: '请输入比例', trigger: 'blur' },
          { validator: rateVaild, trigger: 'blur' }
        ],
        upperLimit: [
          { required: true, message: '请输入金额', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    inputNumber,
    open(row) {
      if (row) {
        this.dialogType = 'edit'
      } else {
        this.dialogType = 'add'
      }
      this.formData = row ? JSON.parse(JSON.stringify(row)) : {
        lower: '',
        upper: '',
        lowerRate: '',
        lowerLimit: '',
        upperRate: '',
        upperLimit: ''
      }
      this.visible = true
    },
    limitInput(value, name, max) {
        let str = (value && value.split("")) || [];
        let reg1 = /\d/;
        let reg2 = /\./;
        // 第一个字符不能为小数点
        if (str[0] == ".") {
            this.formData[name] = ""
            return;
        }
        // 过滤掉除数字和小数点外的字符
        value = str.filter((e) => reg1.test(e) || reg2.test(e));
        // 匹配小数点后只能有两位小数
        let valJoin = value.join("");
        // this.formData[name] =value.replace(/[^0-9]/g,'')
        this.formData[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
        if (this.formData[name] > max) {
          this.formData[name] = max
        }
    },
    confirm() {
      this.$refs['form'].validate((vaild) => {
        if (vaild) {
          this.$emit('confirm', this.dialogType, this.formData)
          this.visible = false
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>